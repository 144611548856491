'use client';

import { Command } from 'lucide-react';

type KeyboardShortcutProps = {
  shortcut: string;
  className?: string;
};

export function KeyboardShortcut({
  shortcut,
  className = '',
}: KeyboardShortcutProps) {
  const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  const keys = shortcut.split('+');

  return (
    <kbd
      className={`inline-flex items-center gap-1 rounded-md border border-background bg-background px-1.5 py-1 text-xs text-primary/50 ${className}`}
    >
      {keys.map((key) => (
        <span key={key}>
          {key.toLowerCase() === 'meta' ? (
            isMac ? (
              <Command className="h-3 w-3 stroke-2" />
            ) : (
              'Ctrl'
            )
          ) : (
            key
          )}
        </span>
      ))}
    </kbd>
  );
}
